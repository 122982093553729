import React from 'react'
import styled from "styled-components"
import axios from 'axios';

const API_PATH = "https://pixelfriendly.co.za/contactform/mail.php";

const Formcontainer = styled.form`
    color: #000;
    z-index:1; 
    input, textarea, select, button{
        @media(max-width:860px){
            padding: 8px;
        }
        color: #303239;
        background-color: #fff;
        border: none;
        box-shadow: 0px 0px 1px rgba(0,0,0,0.35);
        border-right: 1px solid rgba(0,0,0,0.55);
        border-bottom: 1px solid rgba(0,0,0,0.55);
        width: calc(100% - 24px);
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 19px;
        transition: all .5s ease-out;
        &::placeholder{
            transition: all .5s ease-out;
        }
        &:focus{
            background-color: #2E89D6;
            color:#fff;
            &::placeholder{
                color:#fff;
            }
        }
        &[type=submit]{
            color: #fff;
            margin-bottom: 0px;
            width:100%;
            background-color: #2E89D6;
            border:none;
            padding: 8px;
            border: 2px solid #2E89D6;
            transition: all .5s ease-out;
            &:hover{
                background-color: #fff;
                color:#2E89D6;
            }
        }
        &:focus{
            outline:none;
        }
    }
    select{
        width:100%;
        -moz-appearance: none;
        width: calc(100% - 3px);
    }
    textarea{
        height: 80px;
    }
    #email2 { display: none!important; } 
    .response-message{
        max-width:420px;
        font-size:14px;
        padding-top:10px;
        transition: all .5s ease-out;
    }
`


export default class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            number: '',
            subject: '',
            email: '',
            email1: '',
            message: '',
            mailSent: false,
            response: ''
        }
    }  

    componentDidMount(){
        document.getElementById("email1").style.display = 'none';
    }
    
    handleFormSubmit = e => {
        e.preventDefault();
        axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'application/json' },
        data: this.state
        })
        .then(result => {
            this.setState({
                mailSent: result.data.sent,
                response: result.data.message
            })
        })
        .catch(error => this.setState({ response: "Something went wrong, try again or e-mail us directly." })
        );
    };

    render() { 
        return (
            <Formcontainer action="#">
                <div>
                <input type="text" name="firstname" placeholder="Your Name.."
                    onChange={e => this.setState({ fname: e.target.value })}
                />
                </div>
                <div>
                <input type="tel" name="number" placeholder="Your Number.."
                    onChange={e => this.setState({ number: e.target.value })}
                />
                </div>
                <div>
                <input type="email" name="email" placeholder="Your e-mail"
                    onChange={e => this.setState({ email: e.target.value })}
                />
                <input type="email" id="email1" name="email1" placeholder="Your e-mail"
                    onChange={e => this.setState({ email1: e.target.value })}
                />
                <div>
                </div>
                <select name="interest" onChange={e => this.setState({ subject: e.target.value })}>
                    <option value="Interested in?">Interested in?</option>
                    <option value="Web Development">Web Development</option>
                    <option value="Graphic Design">Graphic Design</option>
                    <option value="Web Update">Web Update</option>
                    <option value="Online marketing">Online marketing</option>
                    <option value="Some of the above">Some of the above</option>
                    <option value="Get Pixel Friendly advice">Get Pixel Friendly advice</option>
                </select>
                </div>
                <div>
                <textarea name="message" placeholder="Write something.."
                    onChange={e => this.setState({ message: e.target.value })}
                ></textarea>
                </div>
                <input type="submit" onClick={e => this.handleFormSubmit(e)} value="Submit" />
                <div className='response-message'>
                    {this.state.response}
                </div>
            </Formcontainer>
        )
    }
}