import React from 'react'
import styled from "styled-components"
import {Row} from 'react-flexa';
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import ContactForm from "../components/contantform"
import Send from "../assets/contact-send.svg"
import Address from "../assets/contact-location.svg"
import Number from "../assets/contact-phone.svg"
import Mail from "../assets/contact-email.svg"
import Facebook from "../assets/social-facebook.svg"
import Google from "../assets/social-google.svg"
import Twitter from "../assets/social-twitter.svg"
import Linked from "../assets/social-linked.svg"
import Insta from "../assets/social-insta.svg"
import Cross from "../assets/cross.svg"

// const isBrowser = typeof window !== 'undefined';
// const ScrollOut  = isBrowser? require('scroll-out') : undefined;

const Sendbox = styled.div`
    z-index:5;
    position: fixed;
    bottom: 20px;
    left: 20px;
    padding: 14px 16px 9px 12px;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 2px 2px 10px rgba(0,0,0, 0.4);
    cursor: pointer;
    transition-timing-function: cubic-bezier(.33,2.1,.73,.69);
    transition-duration: 400ms;
    svg{
        width:40px;
        height:auto;
    }
    transition: opacity .4s linear, transform .4s cubic-bezier(.33,2.1,.73,.69);
    opacity: 0;
    transform: translateY(80px) scale(0.4);
    &[data-scroll="in"] {
        opacity: 1;
        transform: translateY(0px) scale(1);
        transition-delay: 0s!important;
        transform: translateX(0px);
        &:hover{
            transform: scale(1.2, 1.2);
        }
    }
    @media(max-width:640px){
        padding: 8px 9px 2px 6px;
        top: 10px;
        bottom: auto;
        transform: translateY(-80px) scale(0.4);
        svg{
            width:26px;
        }

    }
`

const StyledCross = styled(Cross)`
    position:absolute;
    top: 20px;
    right: 10px;
    max-width:40px;
`

const ContactContain = styled.div`
    &.show{
        #send{
            transform: translateX(-100px);
        }
        .cont-bloc{
            transform: translateX(0px);
            pointer-events: all;
            transition: all 0.6s cubic-bezier(0.23, 1.68, 0.68, 0.73);
            &.delay-0{transition-delay:.25s;}
            &.delay-1{transition-delay:.5s;}
        }
        .cont-hold{
            pointer-events:all;
        }
    }

`

const ContactBox = styled.div`
    box-shadow: 2px 2px 10px rgba(0,0,0, 0.4);
    max-width:460px;
    position:relative;
    background-color:#fff;
    color: #303239;
    border-radius:20px;
    font-size:20px;
    padding: 14px 20px;
    margin-bottom: 20px;
    transform: translateX(-50vw);
    pointer-events: none;
    transition: all 0.6s ease-out;
    &.delay-0{transition-delay:.25s;}
    &.delay-1{transition-delay:.5s;}
    h2{
        font-size:28px;
        margin:0px 0px 10px;
    }
    svg{
        height:20px;
        width:auto;
        margin-right: 10px;
        transition-duration: 400ms;
        transform: translate3d(0, 0, 0);
        cursor: pointer;
        &:hover{
            transform: scale(1.3, 1.3) translate3d(0, 0, 0);
            transition-timing-function: cubic-bezier(.33,2.1,.73,.69);
        }
    }
    .social{
        justify-content: space-around;
        svg{
            height: 30px;
        }
    }
    .contact{
        width:480px;
        padding: 20px 20px;
    }
    @media(max-width:1040px){
        transform: translateX(-60vw);
    }
    @media(max-width:860px){
        margin: 0px auto 8px;
        transform: translateX(-100vw);
        padding: 10px 10px;
        font-size: 18px;
        svg{
            height:18px;
        }
        .social{
            height:26px;
        }
    }
    @media(max-width:500px){
        max-width:90%;
        .contact{
            padding: 10px 10px;
        }
    }
`
// let so1;

const ContactHolder = styled.div`
    position: fixed;
    right:51%;
    z-index:5;
    top: 50%;
    transform: translateY(-50%);
    pointer-events:none};
    @media(max-width:1040px){
        right:40%;
    }
    @media(max-width:860px){
        left:0;
        width: 100%;
    }

`
export default class ContactBlock extends React.Component {
    constructor(props) {
        super(props);
        this.showContact = this.showContact.bind(this);
        this.closeContact = this.closeContact.bind(this);
    }

    // componentDidMount(){
    //     so1 = new ScrollOut({
    //         targets: '#send',
    //         offset: 1,
    //         once: true,
    //     });
    // }
    // componentWillUnmount(){
    //     if(so1){ so1.teardown(); }
    // }
    showContact(){
        let contblock = document.getElementById("cont-contain");
        contblock.classList.add("show");
    }
    closeContact(){
        let contblock = document.getElementById("cont-contain");
        contblock.classList.remove("show");
    }

    render() { 
        return (
            <ContactContain id="cont-contain" >
                <Sendbox id="send" onClick={this.showContact}> <Send/> </Sendbox>
                <ContactHolder className="cont-hold">
                    <ContactBox className="cont-bloc">
                        <StyledCross onClick={this.closeContact}/>
                        <h2>Contact Us</h2>
                        <div><Number/><span>+27611460727</span></div>
                        <div><Mail/><span>hello@pixelfriendly.co.za</span></div>
                        <div><Address/><span>45 Astor Road, Klevehill Park, Sandton</span></div>
                    </ContactBox>
                    <ContactBox className="cont-bloc contact delay-0">
                        <ContactForm/>
                    </ContactBox>
                    <ContactBox className="cont-bloc delay-1">
                        <Row alignContent="stretch" alignItems="stretch" className="social"> 
                            <OutboundLink href="https://www.facebook.com/pixelfriendly/"  target="_blank" aria-label="Facebook"><Facebook/></OutboundLink>
                            <OutboundLink href="https://plus.google.com/+PixelfriendlyZa"  target="_blank" aria-label="Google"><Google/></OutboundLink>
                            <OutboundLink href="https://twitter.com/Pixel_Friendly"  target="_blank" aria-label="Twitter"><Twitter/></OutboundLink>
                            <OutboundLink href="http://www.linkedin.com/company/pixel-friendly"  target="_blank" aria-label="Linked"><Linked/></OutboundLink>
                            <OutboundLink href="https://www.instagram.com/pixelfriendly/"  target="_blank" aria-label="Instagram"><Insta/></OutboundLink>
                        </Row> 
                    </ContactBox>
                </ContactHolder>
            </ContactContain>
        )
    }
}