import { createGlobalStyle } from "styled-components"
import MontserratAlternates from '../fonts/MontserratAlternates-Bold.ttf'
import Josefin from '../fonts/JosefinSans-Regular.ttf'
import MontserratAlternateswoff from '../fonts/MontserratAlternates-Bold.woff2'
import Josefinwoff from '../fonts/JosefinSans-Regular.woff2'


const Global = createGlobalStyle`
  @font-face {
    font-family: MontserratAlternates;
    src: local('MontserratAlternates'), url(${MontserratAlternateswoff}) format('woff'),  url(${MontserratAlternates}) format('truetype');
    font-display: block;
  }
  @font-face {
    font-family: Josefin;
    src: local('Josefin'), url(${Josefinwoff}) format('woff'),  url(${Josefin}) format('truetype');
    font-display: block;
  } 
  ::-webkit-scrollbar { 
    display: none; 
  }
  body{
    font-size:18px;
    margin:0px;
    font-family:Josefin;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background-color:#278ADB;
  }

  input, textarea, select, button{
    font-size:18px;
    margin:0px;
    font-family:Josefin;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
  h1, h2, h3, h4, h5, h6{
    font-family:MontserratAlternates,Josefin;
  }
  a, a:link, a:visited, a:hover, a:active {
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;
  }
  .svg-fill.show{
    transition fill-opacity 1.75s ease-in 1.75s, stroke-opacity 1.75s ease-in 1.75s;
    fill-opacity: 1;
    stroke-opacity: 0!important;
    @media(max-width:640){
      transition fill-opacity 1.75s ease-in, stroke-opacity 0s ease-in;
      &#home-logo{
        transition fill-opacity 1.75s ease-in 1.75s, stroke-opacity 1.75s ease-in 1.75s;
      }
    }
  }
  .hidel{
    display:none;
  }
  .stop{
    animation: none!important;
  }
  .section-wrapper{
    overflow: scroll;
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    

  }
`

export default Global